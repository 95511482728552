import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import website from '../../config/website'
import { Layout, Footer, SEO } from '../components'
import { theme, prism } from '../styles'

export default function Snippet({ data, location }) {
  const { snippet } = data

  return (
    <Layout logo customSEO>
      <SEO
        title={`${snippet.frontmatter.title}`}
        pathname={location.pathname}
        desc={snippet.excerpt}
        node={snippet}
        article
      />
      <header css={styles.header}>
        <div>
          <div css={styles.headline}>
            <span>{snippet.frontmatter.date}</span>
          </div>
          <h3 css={styles.title}>{snippet.frontmatter.title}</h3>
        </div>
      </header>
      <main id={website.skipNavId} css={styles.main}>
        <div css={prism} dangerouslySetInnerHTML={{ __html: snippet.html }} />
      </main>
      <Footer />
    </Layout>
  )
}

Snippet.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const styles = {
  headline: {
    fontFamily: theme.fonts.mono,
    fontSize: 15,
    color: '#444',
    marginBottom: '1rem',
    a: {
      fontStyle: 'normal',
      fontWeight: 'normal'
    }
  },
  header: {
    maxWidth: '745px',
    margin: '0 auto',
    padding: '0 1.25rem',
    [theme.media.desktop]: {
      padding: '0 2rem'
    },
    paddingTop: '2rem',
    marginBottom: '2rem',
    [theme.media.desktop]: {
      paddingTop: '4rem'
    }
  },
  title: {
    // fontSize: '1.8rem'
  },
  separator: {
    opacity: 0.8,
    margin: '0 20px'
  },
  main: {
    maxWidth: '840px',
    margin: '0 auto',
    padding: '0 1.25rem',
    paddingBottom: '2rem',
    [theme.media.desktop]: {
      padding: '0 2rem',
      paddingBottom: '2rem'
    },
    'p, ul, ol, h1, h2, h3, h4, hr': {
      maxWidth: '720px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    h2: {
      marginTop: '3rem',
      marginBottom: '1rem'
    },
    li: {
      marginLeft: '1.6rem',
      paddingLeft: '0.5rem'
    },
    hr: {
      maxWidth: '720px',
      background: 'rgba(0,0,0,.08)'
    },
    blockquote: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderLeft: `2px solid ${theme.colors.primary}`,
      padding: '0 1rem',
      [theme.media.desktop]: {
        padding: '0 1.95rem'
      },
      p: {
        fontStyle: 'italic'
      }
    },
    'a.gatsby-resp-image-link': {
      marginBottom: '2rem'
    },
    'pre[class*="language-"]': {
      marginTop: '0 !important',
      borderTopLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important'
    },
    'div[data-language]': {
      marginBottom: '1.5rem',
      maxWidth: '720px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    h3: {
      // border: '1px solid rgb(255, 232, 214)',
      border: '1px solid #dbdbdb',
      borderBottom: 'none',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      maxWidth: '720px',
      padding: '12px 12px 14px',
      fontSize: '14px',
      // color: '#c63c21',
      color: '#000ca8',
      fontWeight: '500',
      // background: 'rgb(255,247,241)',
      fontFamily: "'Roboto Mono', Monaco, 'Courier New', Courier, monospace",
      marginBottom: 0
    }
  }
}

export const pageQuery = graphql`
  query SnippetBySlug($slug: String!) {
    snippet: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
